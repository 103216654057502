import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import noteImg from "../assets/icons/note_white.png";
import cartImg from "../assets/icons/cart_white.png";
import installImg from "../assets/icons/installation_white.png";
import enjoyImg from "../assets/icons/enjoy_white.png";

import noteImg_blue from "../assets/icons/note_blue.png";
import cartImg_blue from "../assets/icons/cart_blue.png";
import installImg_blue from "../assets/icons/installation_blue.png";
import enjoyImg_blue from "../assets/icons/enjoy_blue.png";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "1.5em",
    fontWeight: "bold",
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(8),
  },
  icon: {
    [theme.breakpoints.down("md")]: {
      width: 64,
      height: "auto",
    },
    [theme.breakpoints.up("md")]: {
      width: 108,
      height: "auto",
    },
    [theme.breakpoints.up("lg")]: {
      width: 146,
      height: "auto",
    },
  },
}));
const HowItWorks = ({ isResidential }) => {
  const classes = useStyles();
  return (
    <Box
      width="100%"
      bgcolor={isResidential ? "secondary.main" : "primary.main"}
    >
      <Typography
        color={isResidential ? "primary" : "secondary"}
        align="center"
        className={classes.title}
      >
        HOW TO GET YOUR NEW FLOORING
      </Typography>
      <Box display="flex" flexWrap="wrap" justifyContent="space-evenly" pb={8}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          maxWidth="240px"
          p={2}
        >
          <img
            src={isResidential ? noteImg : noteImg_blue}
            alt="quote"
            className={classes.icon}
          />
          <br />
          <Typography
            color={isResidential ? "primary" : "secondary"}
            variant="h6"
          >
            REQUEST A QUOTE
          </Typography>
          <br />
          <Typography
            color={isResidential ? "primary" : "secondary"}
            variant="h6"
            align="center"
          >
            Schedule a free in-home estimate and we'll arrange a convenient time to discuss your flooring needs. Same-day appointments are available.
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          maxWidth="240px"
          p={2}
        >
          <img
            src={isResidential ? cartImg : cartImg_blue}
            alt="shop"
            className={classes.icon}
          />
          <br />
          <Typography
            color={isResidential ? "primary" : "secondary"}
            variant="h6"
          >
            IN-HOME CONSULTATION
          </Typography>
          <br />
          <Typography
            color={isResidential ? "primary" : "secondary"}
            variant="h6"
            align="center"
          >
            A flooring professional will bring samples to your home, help you pick the right flooring, measure your rooms, and give you an all-inclusive price estimate
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          maxWidth="240px"
          p={2}
        >
          <img
            src={isResidential ? installImg : installImg_blue}
            alt="install"
            className={classes.icon}
          />
          <br />
          <Typography
            color={isResidential ? "primary" : "secondary"}
            variant="h6"
          >
            INSTALLATION
          </Typography>
          <br />
          <Typography
            color={isResidential ? "primary" : "secondary"}
            variant="h6"
            align="center"
          >
            Professional installers will ensure your project is done right. They will move your furniture, remove and haul away your old floor, install your new flooring, and clean up when they’re finished.
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          maxWidth="240px"
          p={2}
        >
          <img
            src={isResidential ? enjoyImg : enjoyImg_blue}
            alt="enjoy"
            className={classes.icon}
          />
          <br />
          <Typography
            color={isResidential ? "primary" : "secondary"}
            variant="h6"
          >
            ENJOY IT
          </Typography>
          <br />
          <Typography
            color={isResidential ? "primary" : "secondary"}
            variant="h6"
            align="center"
          >
            Sit back and enjoy your new flooring! If any issues arise with your flooring we will be happy to resolve them for you.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default HowItWorks;
