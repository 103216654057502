import React from "react";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";

import theme from "../themes/default-theme";

import photo00 from "../assets/gallery/residential/photo00.png";
import photo01 from "../assets/gallery/residential/photo01.png";
import photo02 from "../assets/gallery/residential/photo02.png";
import photo03 from "../assets/gallery/residential/photo03.png";
import photo04 from "../assets/gallery/residential/photo04.png";
import photo05 from "../assets/gallery/commercial/photo00.png";
import photo06 from "../assets/gallery/commercial/photo01.png";
import photo07 from "../assets/gallery/commercial/photo02.png";
import photo08 from "../assets/gallery/commercial/photo03.png";

const photos = [
  photo00,
  photo01,
  photo02,
  photo03,
  photo04,
  photo05,
  photo06,
  photo07,
  photo08,
];

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "1.5em",
    fontWeight: "bold",
    paddingTop: theme.spacing(6),
    marginBottom: theme.spacing(3),
  },
  galleryImage: {
    [theme.breakpoints.down("lg")]: {
      width: 300,
      height: "auto",
    },
    [theme.breakpoints.up("lg")]: {
      width: 400,
      height: "auto",
    },
    margin: theme.spacing(2),
  },
}));

const PhotoGalleryMain = () => {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="xl">
        <Box width="100%" bgcolor="primary.main">
          <Box
            display="flex"
            flexGrow={1}
            flexWrap="wrap"
            justifyContent="space-evenly"
            m={2}
          >
            {photos.map((photo, index) => (
              <img
                src={photo}
                alt={"photo" + index}
                className={classes.galleryImage}
              />
            ))}
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default PhotoGalleryMain;
