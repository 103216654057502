import React from "react";

import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import theme from "../themes/default-theme";

import image from "../assets/coming_soon.png";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    textAlign: "center",
  },
  image: {
    width: "100%",
    height: "100%",
    opacity: 0.5,
    filter: 'brightness("50%")',
  },
  text: {
    fontSize: "1.5em",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

const ComingSoon = () => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="xl">
        <Box className={classes.container} bgcolor="secondary.main">
          <img src={image} alt="coming soon" className={classes.image} />
          <Typography color="primary" className={classes.text}>
            DESIGN YOUR OWN ROOM COMING SOON!
          </Typography>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default ComingSoon;
