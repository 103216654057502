import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import emilyImg from "../assets/testimonials/Emily.png";
import ethanImg from "../assets/testimonials/Ethan.png";

const testimonials = [
  {
    photo: emilyImg,
    quote: "Coverland installed hardwood in my home kitchen. The match was perfect, and the installation crew was fantastic. They took the extra time to make sure our kitchen floor was a smooth transition to our other hardwood areas.",
    author: "L Thompson",
  },
  {
    photo: ethanImg,
    quote: "We got the corporate look we were looking for and Coverland was within the budget. The design of the carpet tiles was done professionally, and we are pleased with the outcome.",
    author: "E Drane",
  },
  {
    photo: ethanImg,
    quote: "The Coverland men do great attention to detail work. They communicate with the customer and are on time from start to completion of the job.",
    author: "J Anderson",
  },
  {
    photo: ethanImg,
    quote: "We have been pleased with our service and quality of materials. We are especially satisfied with the choices for our properties as selections have been carefully and perfectly matched.",
    author: "E Lewis",
  },
];

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "1.5em",
    fontWeight: "bold",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(6),
  },
  avatar: {
    width: "220px",
    height: "auto",
  },
  quote: {
    minHeight: "200px",
    [theme.breakpoints.down("md")]: {
      display: "inline",
      width: "80%",
    },
    [theme.breakpoints.up("md")]: {
      display: "inline",
      width: "70%",
    },
    [theme.breakpoints.up("lg")]: {
      display: "inline",
      width: "60%",
    },
  },
  quoteAuthor: {
    [theme.breakpoints.down("md")]: {
      display: "inline",
      fontSize: "1.5em",
    },
    [theme.breakpoints.up("md")]: {
      display: "inline",
      fontSize: "2em",
    },
  },
  quoteText: {
    [theme.breakpoints.down("md")]: {
      display: "inline",
      fontSize: "1.2em",
    },
    [theme.breakpoints.up("md")]: {
      display: "inline",
      fontSize: "1.5em",
    },
  },
}));

const Testimonials = () => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      flexDirection="column"
      flexGrow={1}
      bgcolor="secondary.main"
      p={4}
    >
      <Typography color="primary" align="center" className={classes.title}>
        TESTIMONIALS
      </Typography>
      <Carousel showThumbs={false} showIndicators={false}>
        {testimonials.map((testimonial) => (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            bgcolor="secondary.main"
          >
            <Box className={classes.quote} m={4}>
              <Typography color="primary" className={classes.quoteText}>
                {testimonial.quote}
              </Typography>
              <br />
              <br />
              <Typography color="primary" className={classes.quoteAuthor}>
                {testimonial.author}
              </Typography>
            </Box>
          </Box>
        ))}
      </Carousel>
    </Box>
  );
};

export default Testimonials;
