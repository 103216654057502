import React from "react";

import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";

import { makeStyles, ThemeProvider } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";

import CustomButton from "./custom-button";

import theme from "../themes/default-theme";

import Flooring from "./flooring";
import HowItWorks from "./howitworks";
import PhotoGallery from "./photo-gallery";

import residentialImg from "../assets/residential/residential.png";

import optionsImg from "../assets/residential/residential_service.png";

const useStyles = makeStyles((theme) => ({
  image: {
    width: "100%",
    height: "100%",
  },
  optionsImage: {
    width: "100%",
    height: "100%",
    filter: "brightness(50%)",
  },
  overlap: {
    position: "absolute",
    [theme.breakpoints.up("xs")]: {
      padding: theme.spacing(1),
      width: "90%",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(3),
      width: "80%",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(4),
      width: "60%",
      top: "50%",
      left: "50%",
      transform: "translate(-70%, -50%)",
    },
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(5),
      width: "40%",
      top: "50%",
      left: "50%",
      transform: "translate(-100%, -40%)",
    },
  },
  overlapTitle: {
    fontWeight: "bold",
    [theme.breakpoints.up("sm")]: {
      display: "inline",
      fontSize: "1.2em",
    },
    [theme.breakpoints.up("md")]: {
      display: "inline",
      fontSize: "1.8em",
    },
  },
  overlapText: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.up("sm")]: {
      display: "inline",
      fontSize: "0.8em",
    },
    [theme.breakpoints.up("md")]: {
      display: "inline",
      fontSize: "1.2em",
    },
  },

  optionsContainer: {
    position: "relative",
  },
  optionsText: {
    position: "absolute",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.up("sm")]: {
      display: "inline",
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      display: "inline",
      width: "80%",
    },
    [theme.breakpoints.up("lg")]: {
      display: "inline",
      width: "65%",
    },
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  optionsTitle: {
    fontWeight: "bold",
    width: '100%',
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.up("sm")]: {
      display: "inline",
      fontSize: "1em",
    },
    [theme.breakpoints.up("md")]: {
      display: "inline",
      fontSize: "1.5em",
    },
    [theme.breakpoints.up("lg")]: {
      display: "inline",
      fontSize: "2em",
    },
  },
  optionsContent: {
    textAlign: 'left',
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.up("sm")]: {
      display: "inline",
      fontSize: "0.8em",
    },
    [theme.breakpoints.up("md")]: {
      display: "inline",
      fontSize: "1.2em",
    },
    [theme.breakpoints.up("lg")]: {
      display: "inline",
      fontSize: "1.5em",
    },
  },
}));

const Residential = () => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="xl">
        <div style={{ position: "relative" }}>
          <img
            src={residentialImg}
            className={classes.image}
            alt="residential"
          />
          <Box className={classes.overlap} bgcolor="primary.main">
            <Typography className={classes.overlapTitle}>
              CHOOSE FROM A VARIETY OF DESIGNS AND EXCELLENT MATERIALS
              <br />
            </Typography>
            <Typography color="secondary" className={classes.overlapText}>
              <br />
              Top quality, name brand carpet and flooring options in the latest styles and colors that are durable and long lasting
              <br />
            </Typography>
            <br />
            <CustomButton
              variant="outlined"
              text="Browse Designs"
              border="4px solid"
              color="#007eff"
              moreStyles={{
                [theme.breakpoints.down("md")]: {
                  fontSize: "0.8em",
                  width: theme.spacing(24),
                  height: theme.spacing(6),
                },
                [theme.breakpoints.up("md")]: {
                  fontSize: "1.1em",
                  width: theme.spacing(32),
                  height: theme.spacing(8),
                },
              }}
            />
          </Box>
        </div>
        <Flooring />
        <Box
            display="flex"
            flexDirection="column"
            className={classes.optionsContainer}
            bgcolor="secondary.main"
            alignItems="center"
            style={{ textAlign: "center" }}
        >
          <img
            src={optionsImg}
            alt="options"
            className={classes.optionsImage}
          />
          <div className={classes.optionsText}>
            <Typography color="primary" className={classes.optionsTitle}>
              RESIDENTIAL SERVICES AND FLOORING OPTIONS
            </Typography>
            <br />
            <br />
            <Typography color="primary" className={classes.optionsContent}>
              Our residential flooring options come in a wide range of looks to match your personal preferences and style
              <br />
              <br />
              <ul>
                <li style={{textAlign: 'left'}}>
                  &quot;Wood&quot; flooring: Engineered Hardwood, Solid Hardwood, Wood Laminate, Vinyl Plank, Porcelain and Ceramic Tile, Sheet Vinyl
                </li>
                <li style={{textAlign: 'left'}}>
                  Carpet: Plush, Frieze, Berber, Pattern, Indoor/Outdoor, Commercial Carpet and Carpet Tile
                </li>
                <li style={{textAlign: 'left'}}>
                  &quot;Tile&quot; flooring: Porcelain and Ceramic Tile, Luxury Vinyl Tile, Sheet Vinyl, Commercial Vinyl Tile
                </li>
              </ul>

            </Typography>
          </div>
        </Box>
        <HowItWorks isResidential={true} />
        <PhotoGallery isResidential={true} />
      </Container>
    </ThemeProvider>
  );
};

export default Residential;
