import React, { useEffect } from "react";

import { Route, Switch, useLocation } from "react-router-dom";

import { useDispatch } from "react-redux";

import Header from "./components/header";
import Footer from "./components/footer";
import Home from "./components/home";
import Commercial from "./components/commercial";
import Residential from "./components/residential";
import ComingSoon from "./components/coming-soon";
import PhotoGalleryMain from "./components/photo-gallery-main";

import { setTab } from "./actions/";

function App() {
  let location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.pathname === "/") {
      dispatch(setTab(0));
    } else if (location.pathname === "/commercial") {
      dispatch(setTab(1));
    } else if (location.pathname === "/residential") {
      dispatch(setTab(2));
    } else if (location.pathname === "/design") {
      dispatch(setTab(3));
    } else if (location.pathname === "/gallery") {
      dispatch(setTab(4));
    }
    // else if (location.pathname === "/testimonials") {
    //   dispatch(setTab(5));
    // }
  }, [location]);

  return (
    <React.Fragment>
      <Header />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/commercial" component={Commercial} />
        <Route exact path="/residential" component={Residential} />
        <Route exact path="/design" component={ComingSoon} />
        <Route exact path="/gallery" component={PhotoGalleryMain} />
      </Switch>
      <Footer />
    </React.Fragment>
  );
}

export default App;
