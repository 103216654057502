import React from "react";

import { useHistory } from "react-router-dom";

import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import { makeStyles, ThemeProvider } from "@material-ui/core/styles";

import CustomButton from "./custom-button";

import theme from "../themes/default-theme";

import Testimonials from "./testimonials";

import carousel0 from "../assets/home/carousel/carousel0.png";
import carousel1 from "../assets/home/carousel/carousel1.png";
import carousel2 from "../assets/home/carousel/carousel2.png";

import residentialImg from "../assets/home/showcase/residential.png";
import commercialImg from "../assets/home/showcase/commercial.png";

import aboutImg from "../assets/home/about.png";

const carousels = [
  {
    image: carousel0,
    bigText:
      "OVER 20 YEARS OF EXPERIENCE INSTALLING COMMERCIAL AND RESIDENTIAL FLOORING. WE CAN HANDLE BIG AND SMALL PROJECTS",
    smallText:
      "CONTACT COVERLAND FLOORING FOR QUALITY FLOORING AND TILE SOLUTIONS DONE RIGHT",
  },
  {
    image: carousel1,
    bigText:
        "OVER 20 YEARS OF EXPERIENCE INSTALLING COMMERCIAL AND RESIDENTIAL FLOORING. WE CAN HANDLE BIG AND SMALL PROJECTS",
    smallText:
        "CONTACT COVERLAND FLOORING FOR QUALITY FLOORING AND TILE SOLUTIONS DONE RIGHT",
  },
  {
    image: carousel2,
    bigText:
        "OVER 20 YEARS OF EXPERIENCE INSTALLING COMMERCIAL AND RESIDENTIAL FLOORING. WE CAN HANDLE BIG AND SMALL PROJECTS",
    smallText:
        "CONTACT COVERLAND FLOORING FOR QUALITY FLOORING AND TILE SOLUTIONS DONE RIGHT",
  },
];

const useStyles = makeStyles((theme) => ({
  image: {
    width: "100%",
    height: "auto",
    filter: "brightness(50%)",
  },
  aboutImage: {
    width: "100%",
    height: "100%",
    opacity: 0.5,
    filter: "brightness(50%)",
  },
  carouselContainer: {
    position: "relative",
    textAlign: "center",
  },
  aboutContainer: {
    position: "relative",
    textAlign: "center",
  },
  showcaseContainerLeft: {
    position: "relative",
  },
  showcaseContainerRight: {
    position: "relative",
  },
  carouselText: {
    position: "absolute",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.up("sm")]: {
      display: "inline",
      width: "90%",
    },
    [theme.breakpoints.up("xl")]: {
      display: "inline",
      width: "85%",
    },
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  carouselBigText: {
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.up("sm")]: {
      display: "inline",
      fontSize: "1.5em",
    },
    [theme.breakpoints.up("md")]: {
      display: "inline",
      fontSize: "2em",
    },
    [theme.breakpoints.up("lg")]: {
      display: "inline",
      fontSize: "2.5em",
    },
  },
  carouselSmallText: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.up("sm")]: {
      display: "inline",
      fontSize: "0.5em",
    },
    [theme.breakpoints.up("md")]: {
      display: "inline",
      fontSize: "0.8em",
    },
    [theme.breakpoints.up("lg")]: {
      display: "inline",
      fontSize: "1.2em",
    },
  },
  aboutText: {
    position: "absolute",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.up("sm")]: {
      display: "inline",
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      display: "inline",
      width: "80%",
    },
    [theme.breakpoints.up("lg")]: {
      display: "inline",
      width: "70%",
    },
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  aboutTitle: {
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.up("sm")]: {
      display: "inline",
      fontSize: "1em",
    },
    [theme.breakpoints.up("md")]: {
      display: "inline",
      fontSize: "1.5em",
    },
    [theme.breakpoints.up("lg")]: {
      display: "inline",
      fontSize: "2em",
    },
  },
  aboutContent: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.up("sm")]: {
      display: "inline",
      fontSize: "0.8em",
    },
    [theme.breakpoints.up("md")]: {
      display: "inline",
      fontSize: "1.2em",
    },
    [theme.breakpoints.up("lg")]: {
      display: "inline",
      fontSize: "1.5em",
    },
  },
  showcaseButton: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    msTransform: "transform(-50%, -50%)",
  },
}));

const Home = () => {
  const classes = useStyles();
  const history = useHistory();

  const handleBrowseResidential = () => {
    history.push("/residential");
  };

  const handleBrowseCommercial = () => {
    history.push("/commercial");
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="xl">
        <Carousel
          showThumbs={false}
          autoPlay={true}
          interval={10000}
          infiniteLoop={true}
          stopOnHover={true}
        >
          {carousels.map((carousel) => (
            <div className={classes.carouselContainer}>
              <img
                src={carousel.image}
                alt="carousel0"
                className={classes.image}
              />
              <div className={classes.carouselText}>
                <Typography color="primary" className={classes.carouselBigText}>
                  {carousel.bigText}
                </Typography>
                <br />
                <br />
                <Typography
                  color="primary"
                  className={classes.carouselSmallText}
                >
                  {carousel.smallText}
                </Typography>
              </div>
            </div>
          ))}
        </Carousel>
        <Grid container justify="center">
          <Grid item xs={12} sm={6}>
            <Box className={classes.showcaseContainerLeft} m={4} mr={3}>
              <img
                src={residentialImg}
                alt="residential"
                className={classes.image}
              />
              <CustomButton
                variant="outlined"
                text="Browse Residential"
                color="white"
                onClick={handleBrowseResidential}
                moreStyles={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  msTransform: "transform(-50%, -50%)",
                  [theme.breakpoints.down("md")]: {
                    fontSize: "0.9em",
                    width: theme.spacing(27),
                    height: theme.spacing(6),
                    border: "3px solid",
                  },
                  [theme.breakpoints.up("md")]: {
                    fontSize: "1.1em",
                    width: theme.spacing(36),
                    height: theme.spacing(8),
                    border: "4px solid",
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box className={classes.showcaseContainerRight} m={4} ml={3}>
              <img
                src={commercialImg}
                alt="commercial"
                className={classes.image}
              />
              <CustomButton
                variant="outlined"
                text="Browse Commercial"
                color="white"
                onClick={handleBrowseCommercial}
                moreStyles={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  msTransform: "transform(-50%, -50%)",
                  [theme.breakpoints.down("md")]: {
                    fontSize: "0.9em",
                    width: theme.spacing(27),
                    height: theme.spacing(6),
                    border: "3px solid",
                  },
                  [theme.breakpoints.up("md")]: {
                    fontSize: "1.1em",
                    width: theme.spacing(36),
                    height: theme.spacing(8),
                    border: "4px solid",
                  },
                }}
              />
            </Box>
          </Grid>
          <Box
            className={classes.aboutContainer}
            mt={1}
            bgcolor="secondary.main"
          >
            <img src={aboutImg} alt="about" className={classes.aboutImage} />
            <div className={classes.aboutText}>
              <Typography color="primary" className={classes.aboutTitle}>
                ABOUT COVERLAND FlOORING
              </Typography>
              <br />
              <br />
              <Typography color="primary" className={classes.aboutContent}>
                Coverland Flooring is a locally owned and operated company specializing in small or large commercial flooring, residential flooring, and tile solutions.
                Having served the greater Cincinnati, OH area for over 20 years our team works directly with homeowners or general contractors who value high quality outsourced flooring and tile installations.
              </Typography>
            </div>
          </Box>
        </Grid>
        <Testimonials />
      </Container>
    </ThemeProvider>
  );
};

export default Home;
