import React, { useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Container from "@material-ui/core/Container";

import PhoneIcon from "@material-ui/icons/Phone";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";
import TwitterIcon from "@material-ui/icons/Twitter";
import PinterestIcon from "@material-ui/icons/Pinterest";
import MenuIcon from "@material-ui/icons/Menu";

import CustomButton from "./custom-button";

import theme from "../themes/default-theme";

import logo from "../assets/logo.png";

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import TextField from "@material-ui/core/TextField";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button } from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import RequestForm from "./RequestForm";

const useStyles = makeStyles((theme) => ({
  logo: {
    [theme.breakpoints.down("sm")]: {
      width: 96,
      height: 60,
    },
    [theme.breakpoints.up("sm")]: {
      width: 144,
      height: 90,
    },
    [theme.breakpoints.up("md")]: {
      width: 192,
      height: 120,
    },
    cursor: "pointer",
  },
  phoneNumber: {
    fontSize: "1.1em",
    fontWeight: "bold",
    margin: theme.spacing(1),
  },
  menuButton: {
    [theme.breakpoints.down("lg")]: {
      display: "inline",
    },
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  headerContact: {
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      flexGrow: 1,
      justifyContent: "flex-end",
      alignItems: "center",
      margin: theme.spacing(2),
    },
  },
  header: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "inline",
      width: "100%",
    },
  },
  headerContent: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexGrow: 1,
      justifyContent: "flex-end",
      alignItems: "center",
      margin: theme.spacing(2),
    },
  },
  tab: {
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      flexGrow: 1,
    },
    [theme.breakpoints.up("xl")]: {
      display: "flex",
      flexGrow: 1,
    },
    justifyContent: "flex-end",
  },
  textColorPrimary: {
    color: "black",
    fontWeight: "bold",
  },
  notification: {
    fontSize: "1.1em",
  },
  primary: {
    fontWeight: "bold",
  },
  divider: {
    borderLeft: "0px solid black",
    height: theme.spacing(8),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(6),
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 30,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "0px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflowY: "scroll",
    height: "65vh",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2, 2, 2),
      height: "100vh",
      overflow: "scroll",
    },
  },
  formGroup: {
    width: "47.2%",
    display: "inline-block",
  },
  formInput: {
    color: "#434343",
    width: "100%",
    backgroundColor: "#fff",
    border: "1px solid #cfcfcf",
    padding: theme.spacing(2),
    margin: theme.spacing(0),
    [theme.breakpoints.down("lg")]: {
      fontSize: "0.8em",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1em",
    },
  },
  formMaterial: {
    border: "1px solid red",
  },
  label: {
    color: "red",
  },
  modalView: {
    width: "840px",
    margin: "0 auto",
    paddingTop: "90px",
    [theme.breakpoints.down("md")]: {
      paddingTop: "80px",
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: "0",
      width: "100%",
    },
  },
  heading: {
    paddingLeft: "12px",
    width: "92%",
    [theme.breakpoints.down("sm")]: {
      width: "88%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "77%",
    },
  },
  modalHeader: {
    display: "flex",
  },
  closeButton: {
    marginTop: "-5px",
    height: "48px",
  },
  mobileContent: {
    padding: "20px 20px 0 13px",
  },

}));

const Header = () => {
  const classes = useStyles();
  const history = useHistory();
  let location = useLocation();

  let [openDrawer, setOpenDrawer] = useState(false);
  let [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const tab = useSelector((state) => state.tab);

  const handleClickLogo = () => {
    history.push("/");
  };

  const toggleMenu = (open) => {
    setOpenDrawer(open);
  };

  const handleHomeClick = () => {
    history.push("/");
  };
  const handleCommercialClick = () => {
    history.push("/commercial");
  };

  const handleResidentialClick = () => {
    history.push("/residential");
  };

  const handleDesignClick = () => {
    history.push("/design");
  };

  const handleGalleryClick = () => {
    history.push("/gallery");
  };

  const handleTestimonialsClick = () => {
    history.push("/testimonials");
  };

  const handleListClick = () => {
    toggleMenu(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <AppBar position="sticky" elevation={0} className={classes.colorPrimary}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <IconButton
              className={classes.menuButton}
              onClick={() => {
                toggleMenu(true);
              }}
            >
              <MenuIcon />
            </IconButton>
            <SwipeableDrawer
              anchor="left"
              open={openDrawer}
              onClose={() => toggleMenu(false)}
            >
              <List onClick={handleListClick}>
                <ListItem button onClick={handleHomeClick} selected={tab === 0}>
                  <ListItemText
                    primary="HOME"
                    classes={{
                      primary: classes.primary,
                    }}
                  />
                </ListItem>
                <ListItem
                  button
                  onClick={handleCommercialClick}
                  selected={tab === 1}
                >
                  <ListItemText
                    primary="COMMERCIAL"
                    classes={{
                      primary: classes.primary,
                    }}
                  />
                </ListItem>
                <ListItem
                  button
                  onClick={handleResidentialClick}
                  selected={tab === 2}
                >
                  <ListItemText
                    primary="RESIDENTIAL"
                    classes={{
                      primary: classes.primary,
                    }}
                  />
                </ListItem>
                {/*<ListItem
                    button
                    onClick={handleDesignClick}
                    selected={tab === 3}
                >
                  <ListItemText
                      primary="DESIGN YOUR OWN ROOM"
                      classes={{
                        primary: classes.primary,
                      }}
                  />
                </ListItem> */}
                <ListItem
                  button
                  onClick={handleGalleryClick}
                  selected={tab === 4}
                >
                  <ListItemText
                    primary="PHOTO GALLERY"
                    classes={{
                      primary: classes.primary,
                    }}
                  />
                </ListItem>
                <Box className={classes.mobileContent}>
                  <CustomButton
                    variant="outlined"
                    text="Request a quote"
                    fontSize="1.1em"
                    width={28}
                    height={8}
                    border="2px solid"
                    color="#007eff"
                    onClick={handleOpen}
                  />
                </Box>
                {/* <ListItem
                  button
                  onClick={handleTestimonialsClick}
                  selected={tab === 5}
                >
                  <ListItemText
                    primary="TESTIMONIALS"
                    classes={{
                      primary: classes.primary,
                    }}
                  />
                </ListItem> */}
              </List>
            </SwipeableDrawer>
            <Box display="flex" flexGrow={1} justifyContent="flex-end">
              <img
                src={logo}
                className={classes.logo}
                alt="logo"
                width="192"
                height="120"
                onClick={handleClickLogo}
              />
            </Box>
            <Box className={classes.header}>
              <Box className={classes.headerContent}>
                {location.pathname === "/commercial" ? (
                  <Box
                    display="flex"
                    flexGrow={1}
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <div className={classes.divider} />
                    <Typography color="secondary" variant="h6">
                      COMMERCIAL FLOORING
                    </Typography>
                  </Box>
                ) : null}
                {location.pathname === "/residential" ? (
                  <Box
                    display="flex"
                    flexGrow={1}
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <div className={classes.divider} />
                    <Typography color="secondary" variant="h6">
                      RESIDENTIAL FLOORING
                    </Typography>
                  </Box>
                ) : null}
                <Box className={classes.headerContact}>
                  <PhoneIcon fontSize="large" />
                  <Typography className={classes.phoneNumber}>
                    <a href="tel:+15138842320">1-513-884-2320</a>
                  </Typography>
                  <Box
                    display="flex"
                    width={theme.spacing(24)}
                    justifyContent="space-evenly"
                    ml={3}
                    mr={6}
                  >
                    <a
                      href="https://www.instagram.com/coverland_flooring/?hl=en"
                      target="_blank"
                      rel="noopener noreferrer"
                      // style={{ visited: theme.palette.secondary }}
                    >
                      <InstagramIcon fontSize="large" color="secondary" />
                    </a>
                    <a
                      href="https://www.youtube.com/channel/UCowG_YrKH3KdjZ5znGwJh5w"
                      target="_blank"
                      rel="noopener noreferrer"
                      // style={{ visited: theme.palette.secondary }}
                    >
                      <YouTubeIcon fontSize="large" color="secondary" />
                    </a>
                    <a
                      href="https://twitter.com/CoverlandFloor"
                      target="_blank"
                      rel="noopener noreferrer"
                      // style={{ visited: theme.palette.secondary }}
                    >
                      <TwitterIcon fontSize="large" color="secondary" />
                    </a>
                    <a
                      href="https://www.pinterest.com/CoverlandFlooringOH/"
                      target="_blank"
                      rel="noopener noreferrer"
                      // style={{ visited: theme.palette.secondary }}
                    >
                      <PinterestIcon fontSize="large" color="secondary" />
                    </a>
                  </Box>
                </Box>
                <CustomButton
                  variant="outlined"
                  text="Request a quote"
                  fontSize="1.1em"
                  width={32}
                  height={8}
                  border="4px solid"
                  color="#007eff"
                  onClick={handleOpen}
                />
                <Modal
                  open={open}
                  onClose={handleClose}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                    onClick: () => {},
                  }}
                  className={classes.modalView}
                >
                  <Fade in={open}>
                    <div className={classes.paper}>
                      <Box className={classes.modalHeader}>
                        <DialogTitle className={classes.heading}>
                          Request A Quote
                        </DialogTitle>
                        <IconButton
                          aria-label="Close"
                          className={classes.closeButton}
                          onClick={handleClose}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Box>
                      <RequestForm />
                    </div>
                  </Fade>
                </Modal>
              </Box>
              <Box className={classes.tab}>
                <Tabs value={tab} textColor="black" scrollButtons="auto">
                  <Tab
                    label="HOME"
                    component={Link}
                    to="/"
                    className={classes.textColorPrimary}
                  />
                  <Tab
                    label="COMMERCIAL"
                    component={Link}
                    to="/commercial"
                    className={classes.textColorPrimary}
                  />
                  <Tab
                    label="RESIDENTIAL"
                    component={Link}
                    to="/residential"
                    className={classes.textColorPrimary}
                  />
                  {/*<Tab
                    label="DESIGN YOUR OWN ROOM"
                    component={Link}
                    to="/design"
                    className={classes.textColorPrimary}
                  />*/}
                  <Tab
                    label="PHOTO GALLERY"
                    component={Link}
                    to="/gallery"
                    className={classes.textColorPrimary}
                  />

                  {/* <Tab
                    label="TESTIMONIALS"
                    component={Link}
                    to="/testimonials"
                    className={classes.textColorPrimary}
                  /> */}
                </Tabs>
              </Box>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </ThemeProvider>
  );
};

export default Header;
