import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const CustomButton = ({
  variant,
  text,
  fontSize,
  fontWeight,
  width,
  height,
  border,
  color,
  backColor,
  moreStyles,
  onClick,
}) => {
  const useStyles = makeStyles((theme) => ({
    button: {
      borderRadius: "0",
      border: border,
      fontSize: fontSize,
      color: color,
      fontWeight: fontWeight,
      width: theme.spacing(width),
      height: theme.spacing(height),
      ...moreStyles,
    },
  }));
  const classes = useStyles();
  return (
    <Button
      variant={variant}
      className={classes.button}
      color={backColor}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

export default CustomButton;
