import React from "react";

import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";

import { makeStyles, ThemeProvider } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";

import CustomButton from "./custom-button";

import theme from "../themes/default-theme";

import Flooring from "./flooring";
import HowItWorks from "./howitworks";
import PhotoGallery from "./photo-gallery";

import residentialImg from "../assets/commercial/commercial.png";

const useStyles = makeStyles((theme) => ({
  image: {
    width: "100%",
    height: "100%",
  },
  optionsBack: {
    width: "100%",
    height: "50%",
  },
  overlap: {
    position: "absolute",
    [theme.breakpoints.up("xs")]: {
      padding: theme.spacing(1),
      width: "90%",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(3),
      width: "80%",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(4),
      width: "60%",
      top: "50%",
      left: "50%",
      transform: "translate(-70%, -50%)",
    },
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(5),
      width: "40%",
      top: "50%",
      left: "50%",
      transform: "translate(-100%, -40%)",
    },
  },
  overlapTitle: {
    fontWeight: "bold",
    [theme.breakpoints.up("sm")]: {
      display: "inline",
      fontSize: "1.2em",
    },
    [theme.breakpoints.up("md")]: {
      display: "inline",
      fontSize: "1.8em",
    },
  },
  overlapText: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.up("sm")]: {
      display: "inline",
      fontSize: "0.8em",
    },
    [theme.breakpoints.up("md")]: {
      display: "inline",
      fontSize: "1.2em",
    },
  },

  optionsText: {
    textAlign: 'left',
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.up("sm")]: {
      display: "inline",
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      display: "inline",
      width: "80%",
    },
    [theme.breakpoints.up("lg")]: {
      display: "inline",
      width: "70%",
    },
  },
  optionsTitle: {
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.up("sm")]: {
      display: "inline",
      fontSize: "1em",
    },
    [theme.breakpoints.up("md")]: {
      display: "inline",
      fontSize: "1.5em",
    },
    [theme.breakpoints.up("lg")]: {
      display: "inline",
      fontSize: "2em",
    },
  },
  optionsContent: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.up("sm")]: {
      display: "inline",
      fontSize: "0.8em",
    },
    [theme.breakpoints.up("md")]: {
      display: "inline",
      fontSize: "1.2em",
    },
    [theme.breakpoints.up("lg")]: {
      display: "inline",
      fontSize: "1.5em",
    },
  },
}));

const Commercial = () => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="xl">
        <div style={{ position: "relative" }}>
          <img
            src={residentialImg}
            className={classes.image}
            alt="commercial"
          />
          <Box className={classes.overlap} bgcolor="secondary.main">
            <Typography color="primary" className={classes.overlapTitle}>
              CHOOSE FROM A VARIETY OF DESIGNS AND EXCELLENT MATERIALS
              <br />
            </Typography>
            <Typography color="primary" className={classes.overlapText}>
              <br />
              Hundreds of flooring options in-stock and ready to install
              <br />
            </Typography>
            <br />
            <CustomButton
              variant="outlined"
              text="Browse Designs"
              border="4px solid"
              color="white"
              moreStyles={{
                [theme.breakpoints.down("md")]: {
                  fontSize: "0.8em",
                  width: theme.spacing(24),
                  height: theme.spacing(6),
                },
                [theme.breakpoints.up("md")]: {
                  fontSize: "1.1em",
                  width: theme.spacing(32),
                  height: theme.spacing(8),
                },
              }}
            />
          </Box>
        </div>
        <Flooring />
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          bgcolor="secondary.main"
          style={{ textAlign: "center" }}
          p={6}
        >
          <Typography color="primary" className={classes.optionsTitle}>
            COMMERCIAL SERVICES AND FLOORING OPTIONS
          </Typography>
          <Box className={classes.optionsText}>
            <br />
            <br />
            <Typography color="primary" className={classes.optionsContent}>
              Our commercial flooring options come in a wide range of looks to match your personal preferences and style
              <br />
              <br />
              <ul>
                <li style={{textAlign: 'left'}}>
                  From offices and retail spaces to schools and medical facilities, we can handle most any flooring project
                </li>
                <li style={{textAlign: 'left'}}>
                  From start to finish we offer full service, high quality project management so you can focus on running your business
                </li>
                <li style={{textAlign: 'left'}}>
                  We will work around your schedule to avoid interruption to your business and customers
                </li>
              </ul>
            </Typography>
          </Box>
        </Box>
        <HowItWorks isResidential={false} />
        <PhotoGallery />
      </Container>
    </ThemeProvider>
  );
};

export default Commercial;
