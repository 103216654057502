import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import r_photo00 from "../assets/gallery/residential/photo00.png";
import r_photo01 from "../assets/gallery/residential/photo01.png";
import r_photo02 from "../assets/gallery/residential/photo02.png";
import r_photo03 from "../assets/gallery/residential/photo03.png";
import r_photo04 from "../assets/gallery/residential/photo04.png";
import r_photo05 from "../assets/gallery/residential/photo05.png";
import r_photo06 from "../assets/gallery/residential/photo06.png";
import r_photo07 from "../assets/gallery/residential/photo07.png";

import c_photo00 from "../assets/gallery/commercial/photo00.png";
import c_photo01 from "../assets/gallery/commercial/photo01.png";
import c_photo02 from "../assets/gallery/commercial/photo02.png";
import c_photo03 from "../assets/gallery/commercial/photo03.png";
import c_photo04 from "../assets/gallery/commercial/photo04.png";
import c_photo05 from "../assets/gallery/commercial/photo05.png";
import c_photo06 from "../assets/gallery/commercial/photo06.png";
import c_photo07 from "../assets/gallery/commercial/photo07.png";

const r_photos = [
  r_photo00,
  r_photo01,
  r_photo02,
  r_photo03,
  r_photo04,
  r_photo05,
  r_photo06,
  r_photo07,
];

const c_photos = [
  c_photo00,
  c_photo01,
  c_photo02,
  c_photo03,
  c_photo04,
  c_photo05,
  c_photo06,
  c_photo07,
];

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "1.5em",
    fontWeight: "bold",
    paddingTop: theme.spacing(6),
    marginBottom: theme.spacing(3),
  },
  galleryImage: {
    [theme.breakpoints.down("xl")]: {
      width: 300,
      height: "auto",
    },
    [theme.breakpoints.up("xl")]: {
      width: 400,
      height: "auto",
    },
    margin: theme.spacing(2),
  },
}));

const PhotoGallery = ({ isResidential }) => {
  const classes = useStyles();
  return (
    <Box
      width="100%"
      bgcolor={isResidential ? "primary.main" : "secondary.main"}
    >
      <Typography
        color={isResidential ? "default" : "primary"}
        align="center"
        className={classes.title}
      >
        PHOTO GALLERY
      </Typography>
      <Box
        display="flex"
        flexGrow={1}
        flexWrap="wrap"
        justifyContent="space-evenly"
        m={2}
      >
        {isResidential
          ? r_photos.map((photo, index) => (
              <img
                src={photo}
                alt={"photo" + index}
                className={classes.galleryImage}
              />
            ))
          : c_photos.map((photo, index) => (
              <img
                src={photo}
                alt={"photo" + index}
                className={classes.galleryImage}
              />
            ))}
      </Box>
      <Box display="flex" flexGrow={1} justifyContent="flex-end" mr={4} pb={4}>
        <Link to="/gallery" style={{ textDecoration: "none" }}>
          <Typography
            variant="h5"
            color={isResidential ? "secondary" : "primary"}
          >
            See More >>
          </Typography>
        </Link>
      </Box>
    </Box>
  );
};

export default PhotoGallery;
