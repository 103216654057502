import React from "react";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import CustomButton from "./custom-button";

import { makeStyles, ThemeProvider } from "@material-ui/core/styles";

import PhoneIcon from "@material-ui/icons/Phone";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import EmailIcon from "@material-ui/icons/Email";

import theme from "../themes/default-theme";
import YouTubeIcon from "@material-ui/icons/YouTube";
import PinterestIcon from "@material-ui/icons/Pinterest";

const useStyles = makeStyles((theme) => ({
  contactLink: {
    color: '#FFFFFF',
    '&:hover': {
      textDecoration: 'none'
    },
    '&:visited': {
      textDecoration: 'underline',
      color: '#FFFFFF',
    },
  },
  footerContainer: {
    backgroundColor: "black",
    padding: theme.spacing(4),
  },
  footerText: {
    margin: theme.spacing(1),
    [theme.breakpoints.down("lg")]: {
      fontSize: "0.8em",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.2em",
    },
  },
  subscribeInput: {
    border: 0,
    color: "white",
    backgroundColor: "#404444",
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    [theme.breakpoints.down("lg")]: {
      fontSize: "0.8em",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1em",
    },
  },
  contact: {
    [theme.breakpoints.down("lg")]: {
      fontSize: "1em",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.5em",
    },

    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
  glplusLink: {
    color: '#FE742E',
    '& hover': {
      color: '#fff'
    }
  },

}));

const Footer = () => {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="xl">
        <div className={classes.footerContainer}>
          <Grid container justify="flex-start">
            <Grid item xs={4} md={2}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                mb={3}
              >
                <Link to="/about" style={{ textDecoration: "none" }}>
                  <Typography color="primary" className={classes.footerText}>
                    ABOUT US
                  </Typography>
                </Link>
                <Link to="/faq" style={{ textDecoration: "none" }}>
                  <Typography color="primary" className={classes.footerText}>
                    F.A.Q.
                  </Typography>
                </Link>
                <Link to="/privacypolicy" style={{ textDecoration: "none" }}>
                  <Typography color="primary" className={classes.footerText}>
                    PRIVACY POLICY
                  </Typography>
                </Link>
              </Box>
            </Grid>
            <Grid item xs={8} md={5}>
              <Typography color="primary" className={classes.footerText}>
                SUBSCRIPTION
              </Typography>
              <Box display="flex" flexWrap="wrap" mr={3} mb={3}>
                <input
                  type="text"
                  placeholder="Enter your email address"
                  className={classes.subscribeInput}
                />
                <CustomButton
                  variant="contained"
                  text="SUBSCRIBE"
                  border="0"
                  backColor="secondary"
                  moreStyles={{
                    margin: theme.spacing(1),
                    [theme.breakpoints.down("md")]: {
                      fontSize: "0.8em",
                      width: theme.spacing(15),
                      height: theme.spacing(6),
                    },
                    [theme.breakpoints.up("md")]: {
                      fontSize: "1.1em",
                      width: theme.spacing(19),
                      height: theme.spacing(7),
                    },
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                mb={3}
              >
                <Typography color="primary" className={classes.footerText}>
                  CONTACT US
                </Typography>
                <Box display="flex" alignItems="center">
                  <PhoneIcon color="primary" fontSize="large" />
                  <Typography color="primary" className={classes.contact}>
                    <a className={classes.contactLink} href="tel:+15138842320">1-513-884-2320</a>
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <EmailIcon color="primary" fontSize="large" />
                  <a
                    href="mailto:sales@coverlandflooring.com?Subject=request_from_website"
                    target="_top"
                    style={{ textDecoration: "none" }}
                  >
                    <Typography color="primary" className={classes.contact}>
                      sales@coverlandflooring.com
                    </Typography>
                  </a>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={2}>
              <Box
                display="flex"
                width={theme.spacing(20)}
                justifyContent="space-between"
                mt={1}
                mb={3}
              >
                <a
                    href="https://www.instagram.com/coverland_flooring/?hl=en"
                    target="_blank"
                    rel="noopener noreferrer"
                    // style={{ visited: theme.palette.secondary }}
                >
                  <InstagramIcon fontSize="large" color="primary" />
                </a>
                <a
                    href="https://www.youtube.com/channel/UCowG_YrKH3KdjZ5znGwJh5w"
                    target="_blank"
                    rel="noopener noreferrer"
                    // style={{ visited: theme.palette.secondary }}
                >
                  <YouTubeIcon fontSize="large" color="primary" />
                </a>
                <a
                    href="https://twitter.com/CoverlandFloor"
                    target="_blank"
                    rel="noopener noreferrer"
                    // style={{ visited: theme.palette.secondary }}
                >
                  <TwitterIcon fontSize="large" color="primary" />
                </a>
                <a
                    href="https://www.pinterest.com/CoverlandFlooringOH/"
                    target="_blank"
                    rel="noopener noreferrer"
                    // style={{ visited: theme.palette.secondary }}
                >
                  <PinterestIcon fontSize="large" color="primary" />
                </a>
              </Box>
            </Grid>
            <Grid item xs={12} md={7}>
              <Typography color="primary" className={classes.footerText}>
                &copy;&nbsp;{new Date().getFullYear()}&nbsp;Copyright Coverland Flooring. All rights reserved.
              </Typography>
            </Grid>
            <Grid item xs={12} md={5}>
              <Typography color="secondary" className={classes.footerText}>
                Site designed and created by <a className={classes.glplusLink} href='https://www.glplusllc.com' target='_blank'>GL Plus, LLC</a>
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Container>
    </ThemeProvider>
  );
};

export default Footer;
