import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";

import { locations, preferredModes, preferredTimes, durations } from "./data";
import TextMask from "./TextMask";
import Service from "../../service";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  formGroup: {
    width: "47%",
    display: "inline-block",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "92%",
    },
  },
  formSelectControl: {
    width: "100%",
  },
  formSelect: {
    width: "100%",
  },
  formInput: {
    width: "100%",
  },
  formMaterial: {
    border: "1px solid red",
  },
  textAlign: {
    textAlign: "right",
  },
  primaryButton: {
    color: "#fff",
    padding: "10px 40px",
    fontWeight: "normal",
    fontSize: "16px",
    letterSpacing: "1px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "30px",
    },
  },
  textGroup: {
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "92%",
    },
  },
  label: {
    color: "red",
  },
}));

export default function RequestForm() {
  const classes = useStyles();
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);

  const RequestFormSchema = Yup.object().shape({
    firstname: Yup.string().required("Required"),
    lastname: Yup.string().required("Required"),
    email: Yup.string()
      .email("Provide vaild email address")
      .required("Required"),
    phone: Yup.string().required("Required"),
    location: Yup.mixed().notOneOf(["default"], "Required"),
    preferredContactMode: Yup.string().required("Required"),
    otherComments: Yup.string().max(500),
  });

  const initialValues = {
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    location: "default",
    preferredContactMode: "Phone",
    preferredContactTime: "Any",
    workToBeCompleted: "",
    otherComments: "",
  };

  // const handleSubmit = async (values, { setSubmitting }) => {
  //   setTimeout(() => {
  //     alert(JSON.stringify(values, 2));
  //   }, 300);
  //   // let response = await Service.send(values);
  // };

  const handleSubmit = (values, { resetForm, setSubmitting }) => {
    setTimeout(() => {
      alert(JSON.stringify(values, 2));
      resetForm(initialValues);
      setSuccess(true);
    });
    // let response = await Service.send(values);
  };

  return (
    <React.Fragment>
      {success && (
        <Alert severity="success" onClose={() => setSuccess(false)}>
          Your Request submitted successfully!
        </Alert>
      )}
      {error && (
        <Alert severity="error" onClose={() => setError(false)}>
          Unable to submit your request now. Please try later.
        </Alert>
      )}
      <Formik
        initialValues={initialValues}
        validateOnBlur={false}
        validationSchema={RequestFormSchema}
        onSubmit={handleSubmit}
        render={({ values, submitForm, isSubmitting, touched, errors }) => (
          <Form className={classes.root}>
            <div>
              <Box
                display="flex-col"
                pl={2}
                mb={3}
                className={classes.formGroup}
              >
                <Field
                  color="secondary"
                  className={classes.formInput}
                  component={TextField}
                  name="firstname"
                  type="text"
                  label="First Name"
                />
              </Box>
              <Box
                display="flex-col"
                pl={2}
                mb={3}
                className={classes.formGroup}
              >
                <Field
                  color="secondary"
                  className={classes.formInput}
                  component={TextField}
                  name="lastname"
                  type="text"
                  label="Last Name"
                />
              </Box>
            </div>
            <div>
              <Box
                display="flex-col"
                pl={2}
                mb={3}
                className={classes.formGroup}
              >
                <Field
                  color="secondary"
                  className={classes.formInput}
                  component={TextField}
                  name="phone"
                  type="tel"
                  label="Phone Number"
                  InputProps={{
                    inputComponent: TextMask,
                  }}
                />
              </Box>
              <Box
                display="flex-col"
                pl={2}
                mb={3}
                className={classes.formGroup}
              >
                <Field
                  color="secondary"
                  className={classes.formInput}
                  component={TextField}
                  name="email"
                  type="email"
                  label="Email Address"
                />
              </Box>
            </div>
            <div>
              <Box
                display="flex-col"
                pl={2}
                mb={3}
                className={classes.formGroup}
              >
                <FormControl className={classes.formSelectControl}>
                  <Field
                    color="secondary"
                    component={TextField}
                    type="text"
                    name="location"
                    label="Type of Location"
                    placeholder="Select location type"
                    select
                    variant="standard"
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {locations.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Field>
                </FormControl>
              </Box>
              <Box
                display="flex-col"
                pl={2}
                mb={3}
                className={classes.formGroup}
              >
                <FormControl className={classes.formSelectControl}>
                  <Field
                    color="secondary"
                    component={TextField}
                    type="text"
                    name="preferredContactMode"
                    label="Preferred Method of Contact"
                    select
                    variant="standard"
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {preferredModes.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Field>
                </FormControl>
              </Box>
            </div>
            <div>
              <Box
                display="flex-col"
                pl={2}
                mb={3}
                className={classes.formGroup}
              >
                <FormControl className={classes.formSelectControl}>
                  <Field
                    color="secondary"
                    component={TextField}
                    type="text"
                    name="preferredContactTime"
                    label="Preferred time for us to contact you"
                    select
                    variant="standard"
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {preferredTimes.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Field>
                </FormControl>
              </Box>
              <Box
                display="flex-col"
                pl={2}
                mb={3}
                className={classes.formGroup}
              >
                <FormControl className={classes.formSelectControl}>
                  <Field
                    color="secondary"
                    component={TextField}
                    type="text"
                    name="workToBeCompleted"
                    label="When would you like the work to be completed?"
                    select
                    variant="standard"
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {durations.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Field>
                </FormControl>
              </Box>
            </div>
            <div>
              <Box
                display="flex-col"
                pl={2}
                mb={3}
                className={classes.textGroup}
              >
                <FormControl className={classes.formSelectControl}>
                  <Field
                    color="secondary"
                    component={TextField}
                    label="Please let us know if you have a specific type of flooring in mind or any additional comments:"
                    multiline
                    rows={4}
                    type="text"
                    name="otherComments"
                    className={classes.formTextarea}
                  />
                </FormControl>
              </Box>
            </div>
            <div className={classes.textAlign}>
              <Box
                display="flex-col"
                pl={2}
                mb={3}
                className={classes.textGroup}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.primaryButton}
                  disabled={isSubmitting}
                  onClick={submitForm}
                >
                  Submit
                </Button>
              </Box>
            </div>
          </Form>
        )}
      />
    </React.Fragment>
  );
}
