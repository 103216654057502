export const locations = [
  {
    label: "Select location type",
    value: "default",
  },
  {
    label: "Commercial",
    value: "Commercial",
  },
  {
    label: "Residential",
    value: "Residential",
  },
];

export const preferredModes = [
  {
    label: "Email",
    value: "Email",
  },
  {
    label: "Phone",
    value: "Phone",
  },
];

export const preferredTimes = [
  {
    label: "Any",
    value: "Any",
  },
  {
    label: "Morning",
    value: "Morning",
  },
  {
    label: "Afternoon",
    value: "Afternoon",
  },
  {
    label: "Evening",
    value: "Evening",
  },
];

export const durations = [
  {
    label: "1-2 weeks",
    value: "1-2 weeks",
  },
  {
    label: "3-4 weeks",
    value: "3-4 weeks",
  },
  {
    label: "1 or more months",
    value: "1 or more months",
  },
  {
    label: "not sure",
    value: "not sure",
  },
];
