import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import carpetImg from "../assets/flooring/carpet.png";
import hardwoodImg from "../assets/flooring/hardwood.png";
import vinylImg from "../assets/flooring/vinyl.png";
import tileImg from "../assets/flooring/tile.png";

const useStyles = makeStyles((theme) => ({
  flooringImage: {
    [theme.breakpoints.down("md")]: {
      width: 150,
      height: "auto",
    },
    [theme.breakpoints.up("md")]: {
      width: 240,
      height: "auto",
    },
    [theme.breakpoints.up("lg")]: {
      width: 300,
      height: "auto",
    },
    [theme.breakpoints.up("xl")]: {
      width: 400,
      height: "auto",
    },

    margin: theme.spacing(2),
  },
  flooringText: {
    [theme.breakpoints.down("md")]: {
      fontSize: "1em",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.5em",
    },
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

const Flooring = () => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      flexGrow={1}
      flexWrap="wrap"
      justifyContent="space-evenly"
      mt={4}
      mb={4}
    >
      <div style={{ position: "relative" }}>
        <img src={carpetImg} alt="carpet" className={classes.flooringImage} />
        <Typography
          color="primary"
          align="center"
          className={classes.flooringText}
        >
          CARPET
        </Typography>
      </div>
      <div style={{ position: "relative" }}>
        <img
          src={hardwoodImg}
          alt="hardwood"
          className={classes.flooringImage}
        />
        <Typography
          color="primary"
          align="center"
          className={classes.flooringText}
        >
          HARDWOOD FLOORING
        </Typography>
      </div>
      <div style={{ position: "relative" }}>
        <img src={vinylImg} alt="vinyl" className={classes.flooringImage} />
        <Typography
          color="primary"
          align="center"
          className={classes.flooringText}
        >
          VINYL
        </Typography>
      </div>
      <div style={{ position: "relative" }}>
        <img src={tileImg} alt="tile" className={classes.flooringImage} />
        <Typography
          color="primary"
          align="center"
          className={classes.flooringText}
        >
          TILE FLOORING
        </Typography>
      </div>
    </Box>
  );
};

export default Flooring;
