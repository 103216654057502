const sgMail = require("@sendgrid/mail");

export default {
  send: async function (data) {
    sgMail.setApiKey(
      "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"
    );

    const { email, ...message } = data;

    const content = {
      to: "sales@coverlandflooring.com",
      from: email,
      subject: `QUOTE REQUESTED FROM WEBSITE`,
      html: `<p>
        <p>First Name : ${message.firstname} </p>
        <p>Last Name : ${message.lastname} </p>
        <p>Phone Number : ${message.phone} </p>
        <p>Email Address : ${email} </p>
        <p>Type Of Location : ${message.location} </p>
        <p>Preferred Method Of Contact : ${message.preferredContactMode} </p>
        <p>Preferred time for us to contact you : ${message.preferredContactTime} </p>
        <p>When would you like the work to be completed? : ${message.workToBeCompleted} </p>
        <p>Please let us know if you have a specific type of flooring in mind or any additional comments : ${message.otherComments} </p>
    </.p>`,
    };

    try {
      return await sgMail.send(content);
    } catch (error) {}
  },
};
